import {IStyleParam, StyleParamType, createStylesParams, wixColorParam} from '@wix/tpa-settings'
import {
  BUTTONS_STYLE,
  DEFAULT_WEEK_START,
  IMAGE_ALIGNMENT,
  IMAGE_HOVER_EFFECT,
  IMAGE_RATIO,
  IMAGE_SCALING,
  ITEM_HEIGHT_TYPE,
  LIST_ALIGNMENT,
  LIST_IMAGE_LAYOUT,
  LIST_IMAGE_SIZE,
  LIST_LAYOUT,
  LIST_LAYOUT_TYPE,
  LIST_LOCATION_AND_DATE_FORMAT,
  MOBILE_LIST_LAYOUT,
  MOBILE_LIST_LAYOUT_TYPE,
  MOBILE_WIDGET_LAYOUT,
  ON_IMAGE_CARD_HEIGHT_BEHAVIOR,
  VERTICAL_ALIGNMENT,
  WIDGET_LAYOUT,
  WIDGET_TYPE,
} from '@wix/wix-events-commons-statics'
import {Override} from '../../commons/types/utils'
import {GetDefaultValueProps, getDefaultFont} from '../../settings-commons/utils/style'
import {ISettingsNumbers, WidgetComponentTextSettings} from './settingsParams'
import {
  getWidgetDateVisibilityDefault,
  getWidgetLocationDateFormatDefault,
  getWidgetLocationVisibilityDefault,
} from './Widget/style-helpers/new-styles'
import {WidgetText} from './Widget/style-helpers/studio/constants'
import {getV2ParamsMap} from './Widget/style-helpers/studio/mapper'
import {
  getSocialShareDefault,
  getWidgetButtonDefaultFont,
  getWidgetFontColorDefault,
  getWidgetFontDefault,
} from './Widget/style-helpers/studio/utils'

type IStylesNumbers = {
  listGeneralTitleFontSizeMobile: StyleParamType.Number
  listHeaderTitleFontSizeMobile: StyleParamType.Number
  listButtonFontSizeMobile: StyleParamType.Number
  listContentTextFontSizeMobile: StyleParamType.Number
  listDateFontSizeMobile: StyleParamType.Number
  listLocationFontSizeCompactMobile: StyleParamType.Number
  listDateFontSizeCompactMobile: StyleParamType.Number
  listHeaderTitleFontSizeCompactMobile: StyleParamType.Number
  listButtonFontSizeCompactMobile: StyleParamType.Number
  cardBorderWidth: StyleParamType.Number
  borderWidth: StyleParamType.Number
  todayButtonBorderWidth: StyleParamType.Number
  todayButtonBorderRadius: StyleParamType.Number
  cardDescriptionFontSize: StyleParamType.Number
  listRoundedButtonBorderRadius: StyleParamType.Number
  listBorderWidth: StyleParamType.Number
  calendarCellBorderWidth: StyleParamType.Number
  calendarBorderWidth: StyleParamType.Number
  buttonsBorderWidth: StyleParamType.Number
  todayRoundedButtonBorderRadius: StyleParamType.Number
  listButtonBorderRadius: StyleParamType.Number
  listButtonBorderWidth: StyleParamType.Number
  cardLocationAndDateFontSize: StyleParamType.Number
  calendarDetailsTitleTextSize: StyleParamType.Number
  calendarDetailsDateLocationTextSize: StyleParamType.Number
  calendarDetailsDescriptionTextSize: StyleParamType.Number
  widgetType: StyleParamType.Number
  listLayout: StyleParamType.Number
  listLayoutType: StyleParamType.Number
  mobileListLayout: StyleParamType.Number
  mobileWidgetLayout: StyleParamType.Number
  mobileListLayoutType: StyleParamType.Number
  widgetLayout: StyleParamType.Number
  alignment: StyleParamType.Number
  contentAlignment: StyleParamType.Number
  listAlignment: StyleParamType.Number
  imagePosition: StyleParamType.Number
  verticalImagePosition: StyleParamType.Number
  imageGridPosition: StyleParamType.Number
  imageScaling: StyleParamType.Number
  multiImageScaling: StyleParamType.Number
  multiImageRatio: StyleParamType.Number
  listImageSize: StyleParamType.Number
  /**
   * @deprecated
   */
  imageRatio: StyleParamType.Number
  listImageLayout: StyleParamType.Number
  locationAndDateFormat: StyleParamType.Number
  listLocationAndDateFormat: StyleParamType.Number
  cardsPerRow: StyleParamType.Number
  cardLocationAndDateFormat: StyleParamType.Number
  buttonsStyle: StyleParamType.Number
  buttonsBorderRadius: StyleParamType.Number
  roundedButtonsBorderRadius: StyleParamType.Number
  buttonHeight: StyleParamType.Number
  oneButtonWidth: StyleParamType.Number
  verticalDividerWidth: StyleParamType.Number
  verticalDividerHeight: StyleParamType.Number
  horizontalDividerWidth: StyleParamType.Number
  horizontalDividerHeight: StyleParamType.Number
  compactHorizontalDividerWidth: StyleParamType.Number
  compactHorizontalDividerHeight: StyleParamType.Number
  countdownMarginBottom: StyleParamType.Number
  titleMarginBottom: StyleParamType.Number
  descriptionMarginBottom: StyleParamType.Number
  dateMarginBottom: StyleParamType.Number
  locationMarginBottom: StyleParamType.Number
  dateAndLocationMarginBottom: StyleParamType.Number
  rsvpMarginBottom: StyleParamType.Number
  imageWidth: StyleParamType.Number
  imageOpacity: StyleParamType.Number
  listButtonStyle: StyleParamType.Number
  cardMargins: StyleParamType.Number
  gapBetweenCards: StyleParamType.Number
  sideBySideLayoutMargins: StyleParamType.Number
  /**
   * @deprecated
   */
  listImageOpacity: StyleParamType.Number
  multiImageOpacity: StyleParamType.Number
  multiImageTopMargin: StyleParamType.Number
  multiImageBottomMargin: StyleParamType.Number
  multiImageLeftMargin: StyleParamType.Number
  multiImageRightMargin: StyleParamType.Number
  multiImagePosition: StyleParamType.Number
  multiImageBorderWidth: StyleParamType.Number
  multiImageTopLeftBorderRadius: StyleParamType.Number
  multiImageTopRightBorderRadius: StyleParamType.Number
  multiImageBottomLeftBorderRadius: StyleParamType.Number
  multiImageBottomRightBorderRadius: StyleParamType.Number
  listImageWidth: StyleParamType.Number
  listDividerWidth: StyleParamType.Number
  listStripWidth: StyleParamType.Number
  calendarWeekStartDay: StyleParamType.Number
  calendarListTextSize: StyleParamType.Number
  calendarListDividerWidth: StyleParamType.Number
  todayButtonStyle: StyleParamType.Number
  recurringFilter: StyleParamType.Number
  listLocationAndDateFormatV2: StyleParamType.Number
  listCountdownTopLeftBorderRadius: StyleParamType.Number
  listCountdownTopRightBorderRadius: StyleParamType.Number
  listCountdownBottomLeftBorderRadius: StyleParamType.Number
  listCountdownBottomRightBorderRadius: StyleParamType.Number
  listTopMargin: StyleParamType.Number
  listBottomMargin: StyleParamType.Number
  listLeftMargin: StyleParamType.Number
  listRightMargin: StyleParamType.Number
  listItemTopPadding: StyleParamType.Number
  listItemBottomPadding: StyleParamType.Number
  listItemLeftPadding: StyleParamType.Number
  listItemRightPadding: StyleParamType.Number
  itemFixedHeight: StyleParamType.Number
  itemHeightType: StyleParamType.Number
  topLeftBorderRadius: StyleParamType.Number
  topRightBorderRadius: StyleParamType.Number
  bottomLeftBorderRadius: StyleParamType.Number
  bottomRightBorderRadius: StyleParamType.Number
  eventBoxShadowDistance: StyleParamType.Number
  eventBoxShadowBlur: StyleParamType.Number
  eventBoxShadowAngle: StyleParamType.Number
  listImageHoverEffect: StyleParamType.Number
  listTextVerticalAlignment: StyleParamType.Number
  listItemBorderAndDividerWidth: StyleParamType.Number
  widgetBorderWidth: StyleParamType.Number
  onImageCardHeightBehavior: StyleParamType.Number
}

type IStylesBooleans = {
  showImage: StyleParamType.Boolean
  showCountdown: StyleParamType.Boolean
  showOfferRibbon: StyleParamType.Boolean
  showRecurringRibbon: StyleParamType.Boolean
  showTitle: StyleParamType.Boolean
  showDescription: StyleParamType.Boolean
  showDate: StyleParamType.Boolean
  showLocation: StyleParamType.Boolean
  showShareOptions: StyleParamType.Boolean
  showFullDividers: StyleParamType.Boolean
  showHorizontalDividers: StyleParamType.Boolean
  showCompactDividers: StyleParamType.Boolean
  showLabels: StyleParamType.Boolean
  showRSVPTitle: StyleParamType.Boolean
  showMembers: StyleParamType.Boolean
  listShowMainTitle: StyleParamType.Boolean
  listShowDate: StyleParamType.Boolean
  listShowVenueName: StyleParamType.Boolean
  listShowFullDate: StyleParamType.Boolean
  listShowLocation: StyleParamType.Boolean
  listShowDescription: StyleParamType.Boolean
  listShowSocialShare: StyleParamType.Boolean
  listShowImage: StyleParamType.Boolean
  listShowAdditionalInfo: StyleParamType.Boolean
  listShowMembers: StyleParamType.Boolean
  listShowCountdown: StyleParamType.Boolean
  showSoldOutRibbon: StyleParamType.Boolean
  /**
   * @deprecated showRibbon is split into multiple other flags
   */
  showRibbon: StyleParamType.Boolean
  showRsvpClosedRibbon: StyleParamType.Boolean
  showJoinWaitlistRibbon: StyleParamType.Boolean
  listExtraInfoShowDate: StyleParamType.Boolean
  listExtraInfoShowLocation: StyleParamType.Boolean
  listShowDateV2: StyleParamType.Boolean
  listShowLocationV2: StyleParamType.Boolean
  eventBoxShadowVisible: StyleParamType.Boolean
}

type IStylesFonts = {
  listHeaderTitleFont: StyleParamType.Font
  listHeaderLocationFont: StyleParamType.Font
  listHeaderDateTimeFont: StyleParamType.Font
  cardDescriptionFont: StyleParamType.Font
  listGeneralTitleFont: StyleParamType.Font
  calendarMonthFont: StyleParamType.Font
  calendarWeekdaysFont: StyleParamType.Font
  calendarDayNumberFont: StyleParamType.Font
  calendarCellEventNameFont: StyleParamType.Font
  listHollowButtonFont: StyleParamType.Font
  listFullButtonFont: StyleParamType.Font
  todayHollowButtonFont: StyleParamType.Font
  todayButtonFont: StyleParamType.Font
  ribbonFont: StyleParamType.Font
  listContentTextFont: StyleParamType.Font
  soldOutRibbonFont: StyleParamType.Font
  rsvpClosedRibbonFont: StyleParamType.Font
  joinWaitlistRibbonFont: StyleParamType.Font
  membershipRibbonFont: StyleParamType.Font
  recurringRibbonFont: StyleParamType.Font
  hollowButtonsFont: StyleParamType.Font
  fullButtonsFont: StyleParamType.Font
  countdownFont: StyleParamType.Font
  titleFont: StyleParamType.Font
  descriptionFont: StyleParamType.Font
  labelsFont: StyleParamType.Font
  compactTextsFont: StyleParamType.Font
  textsFont: StyleParamType.Font

  eventTitleFontV2: StyleParamType.Font
  eventDateFontV2: StyleParamType.Font
  eventLocationFontV2: StyleParamType.Font
  eventDescriptionFontV2: StyleParamType.Font
  linkFontV2: StyleParamType.Font
  listTitleFontV2: StyleParamType.Font
  listFullButtonFontV2: StyleParamType.Font
  listHollowButtonFontV2: StyleParamType.Font
  listCountdownFont: StyleParamType.Font

  hoveredEventTitleFontV2: StyleParamType.Font
  hoveredEventDateFontV2: StyleParamType.Font
  hoveredEventLocationFontV2: StyleParamType.Font
  hoveredEventDescriptionFontV2: StyleParamType.Font
}

type IStylesColors = {
  calendarCellBackground: StyleParamType.Color
  calendarBorderColor: StyleParamType.Color
  listHeaderTitleColor: StyleParamType.Color
  calendarMainActiveColor: StyleParamType.Color
  listContentTitleColor: StyleParamType.Color
  listGeneralTitleColor: StyleParamType.Color
  calendarMonthColor: StyleParamType.Color
  calendarWeekdaysColor: StyleParamType.Color
  calendarDayNumberColor: StyleParamType.Color
  calendarCellEventNameColor: StyleParamType.Color
  listHollowButtonFontColor: StyleParamType.Color
  listFullButtonFontColor: StyleParamType.Color
  todayHollowButtonColor: StyleParamType.Color
  todayButtonColor: StyleParamType.Color
  ribbonTextColor: StyleParamType.Color
  listContentTextColor: StyleParamType.Color
  listHeaderLocationColor: StyleParamType.Color
  listHeaderDateTimeColor: StyleParamType.Color
  soldOutRibbonTextColor: StyleParamType.Color
  rsvpClosedRibbonTextColor: StyleParamType.Color
  joinWaitlistRibbonTextColor: StyleParamType.Color
  membershipRibbonTextColor: StyleParamType.Color
  recurringRibbonTextColor: StyleParamType.Color
  onImageHollowButtonsFontColor: StyleParamType.Color
  hollowButtonsFontColor: StyleParamType.Color
  onImageFullButtonsFontColor: StyleParamType.Color
  fullButtonsFontColor: StyleParamType.Color
  countdownColor: StyleParamType.Color
  onImageCountdownColor: StyleParamType.Color
  titleColor: StyleParamType.Color
  onImageTitleColor: StyleParamType.Color
  onImageDescriptionColor: StyleParamType.Color
  descriptionColor: StyleParamType.Color
  onImageLabelsColor: StyleParamType.Color
  labelsColor: StyleParamType.Color
  onImageTextsColor: StyleParamType.Color
  textsColor: StyleParamType.Color
  calendarCellBorderColor: StyleParamType.Color
  calendarPastDaysBackground: StyleParamType.Color
  cardBackgroundColor: StyleParamType.Color
  cardBorderColor: StyleParamType.Color
  cardsBackgroundColor: StyleParamType.Color
  listButtonBackgroundColor: StyleParamType.Color
  listButtonBorderColor: StyleParamType.Color
  todayButtonBackgroundColor: StyleParamType.Color
  todayButtonBorderColor: StyleParamType.Color
  widgetBackgroundColor: StyleParamType.Color
  widgetBorderColor: StyleParamType.Color
  listItemBorderAndDividerColor: StyleParamType.Color
  listItemBackgroundColor: StyleParamType.Color
  /**
   * @deprecated
   */
  listImageBackgroundColor: StyleParamType.Color
  multiImageBackgroundColor: StyleParamType.Color
  multiImageBorderColor: StyleParamType.Color
  ribbonBackgroundColor: StyleParamType.Color
  listBackgroundColor: StyleParamType.Color
  listBorderColor: StyleParamType.Color
  listDividerColor: StyleParamType.Color
  soldOutRibbonBackgroundColor: StyleParamType.Color
  rsvpClosedRibbonBackgroundColor: StyleParamType.Color
  joinWaitlistRibbonBackgroundColor: StyleParamType.Color
  membershipRibbonBorderColor: StyleParamType.Color
  recurringRibbonBorderColor: StyleParamType.Color
  imageBackgroundColor: StyleParamType.Color
  backgroundColor: StyleParamType.Color
  borderColor: StyleParamType.Color
  onImageButtonsBorderColor: StyleParamType.Color
  buttonsBorderColor: StyleParamType.Color
  onImageButtonsBackgroundColor: StyleParamType.Color
  buttonsBackgroundColor: StyleParamType.Color
  onImageDividerColor: StyleParamType.Color
  dividerColor: StyleParamType.Color
  calendarBackgroundColor: StyleParamType.Color
  listHeaderDateColor: StyleParamType.Color
  listLoadMoreContainerBackgroundColor: StyleParamType.Color
  listCountdownColor: StyleParamType.Color
  listCountdownBorderColor: StyleParamType.Color
  listCountdownBackgroundColor: StyleParamType.Color

  eventTitleColorV2: StyleParamType.Color
  eventDateColorV2: StyleParamType.Color
  eventLocationColorV2: StyleParamType.Color
  eventDescriptionColorV2: StyleParamType.Color
  linkColorV2: StyleParamType.Color

  hoveredEventTitleColorV2: StyleParamType.Color
  hoveredEventDateColorV2: StyleParamType.Color
  hoveredEventLocationColorV2: StyleParamType.Color
  hoveredEventDescriptionColor: StyleParamType.Color
  shareIconsColorV2: StyleParamType.Color
  eventBoxShadowColor: StyleParamType.Color
  imageOverlayColor: StyleParamType.Color
}

type ParamsMapper<Type, Val> = {
  [Property in keyof Type]: Val
}

export type WidgetStyleParams = {
  numbers: ParamsMapper<IStylesNumbers, number>
  booleans: ParamsMapper<IStylesBooleans, boolean> & {responsive: boolean; __IS_RTL__: boolean}
  colors: IStylesColors
  fonts: IStylesFonts
  strings: any
}

export type IStyles = IStylesNumbers & IStylesBooleans & IStylesColors & IStylesFonts

export type WidgetComponentSettings = Override<IStylesNumbers & ISettingsNumbers, number> &
  Override<IStylesBooleans, boolean> & {texts: WidgetComponentTextSettings} & {responsive: boolean}

const customColor = (value: string) => ({
  value,
  name: null,
  opacity: 1,
})

type IWidgetStyles = {
  listHeaderTitleFont: StyleParamType.Font
  listHeaderTitleColor: StyleParamType.Color
  listHeaderLocationFont: StyleParamType.Font
  cardLocationAndDateFontSize: StyleParamType.Number
  listContentTitleColor: StyleParamType.Color
  listHeaderDateTimeFont: StyleParamType.Font
  listHeaderDateTimeColor: StyleParamType.Color
  listContentTextColor: StyleParamType.Color
  listContentTextFont: StyleParamType.Font
  cardDescriptionFontSize: StyleParamType.Number
  listHeaderTitleFontSizeCompactMobile: StyleParamType.Number
  listDateFontSizeCompactMobile: StyleParamType.Number
  listLocationFontSizeCompactMobile: StyleParamType.Number
  listHeaderTitleFontSizeMobile: StyleParamType.Number
  listDateFontSizeMobile: StyleParamType.Number
  listContentTextFontSizeMobile: StyleParamType.Number
  cardDescriptionFont: StyleParamType.Font
  listGeneralTitleFontSizeMobile: StyleParamType.Number
  listGeneralTitleFont: StyleParamType.Font
  listLayout: StyleParamType.Number
  listLayoutType: StyleParamType.Number
  mobileListLayout: StyleParamType.Number
  listHeaderLocationColor: StyleParamType.Color
  listFullButtonFont: StyleParamType.Font
  listHollowButtonFont: StyleParamType.Font
  listButtonFontSizeMobile: StyleParamType.Number
  listButtonFontSizeCompactMobile: StyleParamType.Number
  listShowDate: StyleParamType.Boolean
  listShowVenueName: StyleParamType.Boolean
  listShowFullDate: StyleParamType.Boolean
  listShowLocation: StyleParamType.Boolean
  listLocationAndDateFormat: StyleParamType.Number
  cardLocationAndDateFormat: StyleParamType.Number
  cardMargins: StyleParamType.Number
  sideBySideLayoutMargins: StyleParamType.Number
  listStripWidth: StyleParamType.Number
  cardBackgroundColor: StyleParamType.Color
  cardsBackgroundColor: StyleParamType.Color
  listBackgroundColor: StyleParamType.Color
  cardBorderColor: StyleParamType.Color
  listDividerColor: StyleParamType.Color
  cardBorderWidth: StyleParamType.Number
  listDividerWidth: StyleParamType.Number
  listBorderColor: StyleParamType.Color
  listBorderWidth: StyleParamType.Number
  listShowImage: StyleParamType.Boolean
  mobileWidgetLayout: StyleParamType.Number
  mobileListLayoutType: StyleParamType.Number
  widgetType: StyleParamType.Number
}

export const widgetStyles = createStylesParams<IWidgetStyles>({
  listHeaderTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 26,
    }),
  },
  listHeaderTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  listHeaderLocationFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  cardLocationAndDateFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  listContentTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  listHeaderDateTimeFont: {
    type: StyleParamType.Font,
    getDefaultValue: params => ({
      ...params.getStyleParamValue(widgetStyles.listHeaderTitleFont),
      size: 16,
    }),
  },
  listHeaderDateTimeColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(widgetStyles.listHeaderTitleColor),
  },
  listContentTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  listContentTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 14,
    }),
  },
  cardDescriptionFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 15,
  },
  listHeaderTitleFontSizeCompactMobile: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  listDateFontSizeCompactMobile: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  listLocationFontSizeCompactMobile: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  listHeaderTitleFontSizeMobile: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  listDateFontSizeMobile: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  listContentTextFontSizeMobile: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  cardDescriptionFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  listGeneralTitleFontSizeMobile: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 28
    },
  },
  listGeneralTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: (params: GetDefaultValueProps) =>
      getDefaultFont('Page-title', {
        size: 36,
      })(params),
  },
  listLayout: {
    type: StyleParamType.Number,
    getDefaultValue: () => LIST_LAYOUT.NEW_GRID,
  },
  listLayoutType: {
    type: StyleParamType.Number,
    getDefaultValue: () => LIST_LAYOUT_TYPE.STRIPS,
  },
  mobileListLayout: {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => {
      if (getStyleParamValue(widgetStyles.listLayout) === LIST_LAYOUT.ON_IMAGE) {
        return MOBILE_LIST_LAYOUT.ON_IMAGE
      }

      return MOBILE_LIST_LAYOUT.FULL
    },
  },
  listHeaderLocationColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  listFullButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  listHollowButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  listButtonFontSizeMobile: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 16
    },
  },
  listButtonFontSizeCompactMobile: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 14
    },
  },
  listShowDate: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  listShowVenueName: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  listShowFullDate: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  listShowLocation: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  listLocationAndDateFormat: {
    type: StyleParamType.Number,
    getDefaultValue: () => LIST_LOCATION_AND_DATE_FORMAT.COMPACT,
  },
  cardLocationAndDateFormat: {
    type: StyleParamType.Number,
    getDefaultValue: () => LIST_LOCATION_AND_DATE_FORMAT.COMPACT,
  },
  cardMargins: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  sideBySideLayoutMargins: {
    type: StyleParamType.Number,
    getDefaultValue: () => 30,
  },
  listStripWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  cardBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  cardsBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0),
  },
  listBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  cardBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-2', 1),
  },
  listDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-2', 1),
  },
  cardBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  listDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  listBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-2', 1),
  },
  listBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  listShowImage: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  mobileWidgetLayout: {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => {
      const mobileListLayout = getStyleParamValue(widgetStyles.mobileListLayout)

      return mobileListLayout === MOBILE_LIST_LAYOUT.COMPACT ||
        getStyleParamValue(widgetStyles.listLayout) === LIST_LAYOUT.LIST
        ? MOBILE_WIDGET_LAYOUT.LIST
        : mobileListLayout === MOBILE_LIST_LAYOUT.ON_IMAGE
        ? MOBILE_WIDGET_LAYOUT.ON_IMAGE
        : MOBILE_WIDGET_LAYOUT.GRID
    },
  },
  mobileListLayoutType: {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) =>
      getStyleParamValue(widgetStyles.mobileListLayout) === MOBILE_LIST_LAYOUT.COMPACT
        ? MOBILE_LIST_LAYOUT_TYPE.STRIPS
        : getStyleParamValue(widgetStyles.listLayoutType) === LIST_LAYOUT_TYPE.CARDS
        ? MOBILE_LIST_LAYOUT_TYPE.CARDS
        : MOBILE_LIST_LAYOUT_TYPE.FULL,
  },
  widgetType: {
    type: StyleParamType.Number,
    getDefaultValue: () => WIDGET_TYPE.LIST,
  },
})

const paramsMap = getV2ParamsMap()

const getDefaultListMargin: IStyleParam['getDefaultValue'] = ({isMobile, getStyleParamValue}) => {
  const layout = getStyleParamValue(widgetStyles.listLayout)

  return isMobile
    ? 20
    : [LIST_LAYOUT.NEW_GRID, LIST_LAYOUT.GRID].includes(layout)
    ? getStyleParamValue(widgetStyles.cardMargins)
    : [LIST_LAYOUT.SIDE_BY_SIDE, LIST_LAYOUT.CALENDAR, LIST_LAYOUT.ON_IMAGE].includes(layout)
    ? 20
    : 30
}

const getDefaultListItemVerticalPadding: IStyleParam['getDefaultValue'] = ({isMobile, getStyleParamValue}) => {
  const layout = getStyleParamValue(widgetStyles.listLayout)
  const featuredWidget = getStyleParamValue(widgetStyles.widgetType) === WIDGET_TYPE.FEATURED
  const listPadding = getStyleParamValue(widgetStyles.listStripWidth) + 10

  if (featuredWidget && layout === LIST_LAYOUT.ON_IMAGE) {
    return isMobile ? 120 : 150
  }

  if (isMobile) {
    return getStyleParamValue(widgetStyles.mobileWidgetLayout) === MOBILE_WIDGET_LAYOUT.LIST ? listPadding : 20
  } else {
    return layout === LIST_LAYOUT.SIDE_BY_SIDE ? 60 : layout === LIST_LAYOUT.LIST ? listPadding : 32
  }
}

const getDefaultListItemHorizontalPadding: IStyleParam['getDefaultValue'] = ({isMobile, getStyleParamValue}) => {
  const layout = getStyleParamValue(widgetStyles.listLayout)

  if (isMobile) {
    return getStyleParamValue(widgetStyles.mobileWidgetLayout) === MOBILE_WIDGET_LAYOUT.LIST
      ? getStyleParamValue(widgetStyles.mobileListLayoutType) === MOBILE_LIST_LAYOUT_TYPE.CARDS
        ? 30
        : 0
      : 20
  } else {
    return layout === LIST_LAYOUT.SIDE_BY_SIDE
      ? 60
      : layout === LIST_LAYOUT.LIST
      ? getStyleParamValue(widgetStyles.listLayoutType) === LIST_LAYOUT_TYPE.STRIPS
        ? 0
        : 30
      : 32
  }
}

const getDefaultRibbonColor: IStyleParam['getDefaultValue'] = params => {
  const {isMobile, getStyleParamValue} = params
  const showImage = getStyleParamValue(widgetStyles.listShowImage)
  const onImage = isMobile
    ? getStyleParamValue(widgetStyles.mobileWidgetLayout) === MOBILE_WIDGET_LAYOUT.ON_IMAGE
    : getStyleParamValue(widgetStyles.listLayout) === LIST_LAYOUT.ON_IMAGE

  return onImage && showImage ? wixColorParam('color-1')(params) : wixColorParam('color-5')(params)
}

const widgetStylesV2 = createStylesParams({
  listTitleFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params => getWidgetFontDefault({params, widgetText: WidgetText.LIST_TITLE, map: paramsMap}),
  },
  eventTitleColorV2: {
    type: StyleParamType.Color,
    getDefaultValue: params => getWidgetFontColorDefault({params, widgetText: WidgetText.TITLE, map: paramsMap}),
  },
  eventDateColorV2: {
    type: StyleParamType.Color,
    getDefaultValue: params => getWidgetFontColorDefault({params, widgetText: WidgetText.DATE, map: paramsMap}),
  },
  eventLocationColorV2: {
    type: StyleParamType.Color,
    getDefaultValue: params => getWidgetFontColorDefault({params, widgetText: WidgetText.LOCATION, map: paramsMap}),
  },
  eventDescriptionColorV2: {
    type: StyleParamType.Color,
    getDefaultValue: params => getWidgetFontColorDefault({params, widgetText: WidgetText.DESCRIPTION, map: paramsMap}),
  },
  linkColorV2: {
    type: StyleParamType.Color,
    getDefaultValue: params => getWidgetFontColorDefault({params, widgetText: WidgetText.LINK, map: paramsMap}),
  },
  shareIconsColorV2: {type: StyleParamType.Color, getDefaultValue: getSocialShareDefault},
  eventTitleFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params => getWidgetFontDefault({params, widgetText: WidgetText.TITLE, map: paramsMap}),
  },
  eventDateFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params => getWidgetFontDefault({params, widgetText: WidgetText.DATE, map: paramsMap}),
  },
  eventLocationFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params => getWidgetFontDefault({params, widgetText: WidgetText.LOCATION, map: paramsMap}),
  },
  eventDescriptionFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params => getWidgetFontDefault({params, widgetText: WidgetText.DESCRIPTION, map: paramsMap}),
  },
  linkFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params => getWidgetFontDefault({params, widgetText: WidgetText.LINK, map: paramsMap}),
  },
  listFullButtonFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params =>
      getWidgetButtonDefaultFont({
        params,
        fontParam: widgetStyles.listFullButtonFont,
        mobileSizeParam: widgetStyles.listButtonFontSizeMobile,
        mobileCompactSizeParam: widgetStyles.listButtonFontSizeCompactMobile,
      }),
  },
  listHollowButtonFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params =>
      getWidgetButtonDefaultFont({
        params,
        fontParam: widgetStyles.listHollowButtonFont,
        mobileSizeParam: widgetStyles.listButtonFontSizeMobile,
        mobileCompactSizeParam: widgetStyles.listButtonFontSizeCompactMobile,
      }),
  },
  listExtraInfoShowDate: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(widgetStyles.listShowFullDate),
  },
  listExtraInfoShowLocation: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(widgetStyles.listShowLocation),
  },
  listShowDateV2: {
    type: StyleParamType.Boolean,
    getDefaultValue: getWidgetDateVisibilityDefault,
  },
  listShowLocationV2: {
    type: StyleParamType.Boolean,
    getDefaultValue: getWidgetLocationVisibilityDefault,
  },
  listLocationAndDateFormatV2: {
    type: StyleParamType.Number,
    getDefaultValue: getWidgetLocationDateFormatDefault,
  },
})

const hoveredWidgetStylesV2 = createStylesParams({
  hoveredEventTitleColorV2: {
    type: StyleParamType.Color,
    getDefaultValue: params =>
      getWidgetFontColorDefault({
        params,
        widgetText: WidgetText.TITLE,
        map: paramsMap,
        hover: true,
        defaultParam: widgetStylesV2.eventTitleColorV2,
      }),
  },
  hoveredEventDateColorV2: {
    type: StyleParamType.Color,
    getDefaultValue: params =>
      getWidgetFontColorDefault({
        params,
        widgetText: WidgetText.DATE,
        map: paramsMap,
        hover: true,
        defaultParam: widgetStylesV2.eventDateColorV2,
      }),
  },
  hoveredEventLocationColorV2: {
    type: StyleParamType.Color,
    getDefaultValue: params =>
      getWidgetFontColorDefault({
        params,
        widgetText: WidgetText.LOCATION,
        map: paramsMap,
        hover: true,
        defaultParam: widgetStylesV2.eventLocationColorV2,
      }),
  },
  hoveredEventDescriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: params =>
      getWidgetFontColorDefault({
        params,
        widgetText: WidgetText.DESCRIPTION,
        map: paramsMap,
        hover: true,
        defaultParam: widgetStylesV2.eventDescriptionColorV2,
      }),
  },
  hoveredEventTitleFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params =>
      getWidgetFontDefault({
        params,
        widgetText: WidgetText.TITLE,
        map: paramsMap,
        hover: true,
        defaultParam: widgetStylesV2.eventTitleFontV2,
      }),
  },
  hoveredEventDateFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params =>
      getWidgetFontDefault({
        params,
        widgetText: WidgetText.DATE,
        map: paramsMap,
        hover: true,
        defaultParam: widgetStylesV2.eventDateFontV2,
      }),
  },
  hoveredEventLocationFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params =>
      getWidgetFontDefault({
        params,
        widgetText: WidgetText.LOCATION,
        map: paramsMap,
        hover: true,
        defaultParam: widgetStylesV2.eventLocationFontV2,
      }),
  },
  hoveredEventDescriptionFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params =>
      getWidgetFontDefault({
        params,
        widgetText: WidgetText.DESCRIPTION,
        map: paramsMap,
        hover: true,
        defaultParam: widgetStylesV2.eventDescriptionFontV2,
      }),
  },
})

const styleParams: Record<keyof IStyles, IStyleParam> = {
  ...widgetStyles,
  onImageCardHeightBehavior: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return ON_IMAGE_CARD_HEIGHT_BEHAVIOR.FIXED
    },
  },
  borderWidth: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 0
    },
  },
  todayButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 1
    },
  },
  todayButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 0
    },
  },
  listRoundedButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 100
    },
  },
  calendarCellBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 1
    },
  },
  calendarBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 0
    },
  },
  buttonsBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 1
    },
  },
  todayRoundedButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 100
    },
  },
  listButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 0
    },
  },
  listButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 1
    },
  },
  calendarDetailsTitleTextSize: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 16
    },
  },
  calendarDetailsDateLocationTextSize: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 14
    },
  },
  calendarDetailsDescriptionTextSize: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 14
    },
  },
  widgetLayout: {
    type: StyleParamType.Number,
    getDefaultValue: () => WIDGET_LAYOUT.FULL,
  },
  alignment: {
    type: StyleParamType.Number,
    getDefaultValue: () => 2,
  },
  contentAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: () => 2,
  },
  listAlignment: {
    type: StyleParamType.Number,
    inheritDesktop: false,
    getDefaultValue: ({isMobile, getStyleParamValue}) => {
      const mobileOnImage = getStyleParamValue(widgetStyles.mobileWidgetLayout) === MOBILE_WIDGET_LAYOUT.ON_IMAGE

      return isMobile && !mobileOnImage ? LIST_ALIGNMENT.LEFT : LIST_ALIGNMENT.CENTER
    },
  },
  imagePosition: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  verticalImagePosition: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  imageGridPosition: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  imageScaling: {
    type: StyleParamType.Number,
    getDefaultValue: () => IMAGE_SCALING.CROP,
  },
  multiImageScaling: {
    type: StyleParamType.Number,
    getDefaultValue: () => IMAGE_SCALING.CROP,
  },
  // if not set defaults to imageRatio
  multiImageRatio: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  listImageSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => LIST_IMAGE_SIZE.SMALL,
  },
  imageRatio: {
    type: StyleParamType.Number,
    getDefaultValue: () => IMAGE_RATIO['1:1'],
  },
  listImageLayout: {
    type: StyleParamType.Number,
    getDefaultValue: ({isRTL}) => (isRTL ? LIST_IMAGE_LAYOUT.RIGHT : LIST_IMAGE_LAYOUT.LEFT),
  },
  locationAndDateFormat: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  cardsPerRow: {
    type: StyleParamType.Number,
    getDefaultValue: () => 3,
  },
  buttonsStyle: {
    type: StyleParamType.Number,
    getDefaultValue: () => BUTTONS_STYLE.FULL,
  },
  buttonsBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  roundedButtonsBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 100,
  },
  buttonHeight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 44,
  },
  oneButtonWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 140,
  },
  verticalDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  verticalDividerHeight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 94,
  },
  horizontalDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  horizontalDividerHeight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  compactHorizontalDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 36,
  },
  compactHorizontalDividerHeight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  countdownMarginBottom: {
    type: StyleParamType.Number,
    getDefaultValue: () => 30,
  },
  titleMarginBottom: {
    type: StyleParamType.Number,
    getDefaultValue: () => 36,
  },
  descriptionMarginBottom: {
    type: StyleParamType.Number,
    getDefaultValue: () => 42,
  },
  dateMarginBottom: {
    type: StyleParamType.Number,
    getDefaultValue: () => 42,
  },
  locationMarginBottom: {
    type: StyleParamType.Number,
    getDefaultValue: () => 54,
  },
  dateAndLocationMarginBottom: {
    type: StyleParamType.Number,
    getDefaultValue: () => 54,
  },
  rsvpMarginBottom: {
    type: StyleParamType.Number,
    getDefaultValue: () => 30,
  },
  imageWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 50,
  },
  imageOpacity: {
    type: StyleParamType.Number,
    getDefaultValue: () => 70,
  },
  listButtonStyle: {
    type: StyleParamType.Number,
    getDefaultValue: () => BUTTONS_STYLE.FULL,
  },
  listTopMargin: {
    type: StyleParamType.Number,
    getDefaultValue: getDefaultListMargin,
  },
  listBottomMargin: {
    type: StyleParamType.Number,
    getDefaultValue: getDefaultListMargin,
  },
  listLeftMargin: {
    type: StyleParamType.Number,
    getDefaultValue: getDefaultListMargin,
  },
  listRightMargin: {
    type: StyleParamType.Number,
    getDefaultValue: getDefaultListMargin,
  },
  listItemTopPadding: {
    type: StyleParamType.Number,
    getDefaultValue: getDefaultListItemVerticalPadding,
  },
  listItemBottomPadding: {
    type: StyleParamType.Number,
    getDefaultValue: getDefaultListItemVerticalPadding,
  },
  listItemLeftPadding: {
    type: StyleParamType.Number,
    getDefaultValue: getDefaultListItemHorizontalPadding,
  },
  listItemRightPadding: {
    type: StyleParamType.Number,
    getDefaultValue: getDefaultListItemHorizontalPadding,
  },
  gapBetweenCards: {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => {
      const layout = getStyleParamValue(widgetStyles.listLayout)

      return layout === LIST_LAYOUT.NEW_GRID || layout === LIST_LAYOUT.GRID || layout === LIST_LAYOUT.ON_IMAGE
        ? getStyleParamValue(widgetStyles.cardMargins)
        : layout === LIST_LAYOUT.SIDE_BY_SIDE
        ? getStyleParamValue(widgetStyles.sideBySideLayoutMargins)
        : 30
    },
  },
  listImageOpacity: {
    type: StyleParamType.Number,
    getDefaultValue: () => 100,
  },
  multiImageOpacity: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  multiImageTopMargin: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  multiImageBottomMargin: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  multiImageLeftMargin: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  multiImageRightMargin: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  multiImagePosition: {
    type: StyleParamType.Number,
    getDefaultValue: () => IMAGE_ALIGNMENT.CENTER,
  },
  multiImageBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  multiImageTopLeftBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  multiImageTopRightBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  multiImageBottomLeftBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  multiImageBottomRightBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  listImageWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 50,
  },
  calendarWeekStartDay: {
    type: StyleParamType.Number,
    getDefaultValue: () => DEFAULT_WEEK_START,
  },
  calendarListTextSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  calendarListDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  todayButtonStyle: {
    type: StyleParamType.Number,
    getDefaultValue: () => BUTTONS_STYLE.HOLLOW,
  },
  recurringFilter: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  showImage: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showCountdown: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  showOfferRibbon: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showRecurringRibbon: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showTitle: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showDescription: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  showDate: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showLocation: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showShareOptions: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showFullDividers: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showHorizontalDividers: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showCompactDividers: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showLabels: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showRSVPTitle: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  showMembers: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  listShowMainTitle: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  listShowDescription: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  listShowSocialShare: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  listShowAdditionalInfo: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showSoldOutRibbon: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  showRibbon: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  showRsvpClosedRibbon: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  showJoinWaitlistRibbon: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  calendarCellBackground: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  calendarBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-2'),
  },
  calendarMainActiveColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  listGeneralTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  calendarMonthColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  calendarMonthFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 18,
    }),
  },
  calendarWeekdaysColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  calendarWeekdaysFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 14,
    }),
  },
  calendarDayNumberColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  calendarDayNumberFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 24,
    }),
  },
  calendarCellEventNameColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  calendarCellEventNameFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 14,
    }),
  },
  listHollowButtonFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  listFullButtonFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  todayHollowButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  todayHollowButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 14,
    }),
  },
  todayButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  todayButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 14,
    }),
  },
  ribbonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: () => customColor('#FFFFFF'),
  },
  ribbonFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 14,
    }),
  },
  soldOutRibbonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: () => customColor('#FFFFFF'),
  },
  soldOutRibbonFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 14,
    }),
  },
  rsvpClosedRibbonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: () => customColor('#FFFFFF'),
  },
  rsvpClosedRibbonFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 14,
    }),
  },
  joinWaitlistRibbonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: () => customColor('#FFFFFF'),
  },
  joinWaitlistRibbonFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 14,
    }),
  },
  membershipRibbonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: getDefaultRibbonColor,
  },
  membershipRibbonFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 14,
    }),
  },
  recurringRibbonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: getDefaultRibbonColor,
  },
  recurringRibbonFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 14,
    }),
  },
  onImageHollowButtonsFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  hollowButtonsFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  onImageFullButtonsFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  fullButtonsFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  hollowButtonsFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  fullButtonsFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  countdownFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 20,
    }),
  },
  countdownColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  onImageCountdownColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  titleFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Page-title', {
      size: 46,
    }),
  },
  titleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  onImageTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  descriptionFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 15,
    }),
  },
  onImageDescriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  descriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  labelsFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  onImageLabelsColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  labelsColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  compactTextsFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 24,
    }),
  },
  textsFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 15,
    }),
  },
  onImageTextsColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  textsColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  calendarCellBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-2', 1),
  },
  calendarPastDaysBackground: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-2', 0.1),
  },
  widgetBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({isMobile, getStyleParamValue}) => {
      const mobileCompact =
        getStyleParamValue(widgetStyles.mobileWidgetLayout) === MOBILE_WIDGET_LAYOUT.LIST &&
        getStyleParamValue(widgetStyles.mobileListLayoutType) !== MOBILE_LIST_LAYOUT_TYPE.FULL

      return getStyleParamValue(widgetStyles.listLayout) === LIST_LAYOUT.LIST
        ? getStyleParamValue(widgetStyles.listBackgroundColor)
        : isMobile && mobileCompact
        ? getStyleParamValue(widgetStyles.cardBackgroundColor)
        : getStyleParamValue(widgetStyles.cardsBackgroundColor)
    },
  },
  widgetBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({colors, getStyleParamValue}) =>
      getStyleParamValue(widgetStyles.listLayout) === LIST_LAYOUT.LIST
        ? getStyleParamValue(widgetStyles.listBorderColor)
        : wixColorParam('color-2', 1)({colors}),
  },
  widgetBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) =>
      getStyleParamValue(widgetStyles.listLayout) === LIST_LAYOUT.LIST
        ? getStyleParamValue(widgetStyles.listBorderWidth)
        : 0,
  },
  listItemBorderAndDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({getStyleParamValue}) =>
      getStyleParamValue(widgetStyles.listLayout) === LIST_LAYOUT.LIST
        ? getStyleParamValue(widgetStyles.listDividerColor)
        : getStyleParamValue(widgetStyles.cardBorderColor),
  },
  listItemBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({colors, getStyleParamValue}) =>
      getStyleParamValue(widgetStyles.listLayout) === LIST_LAYOUT.LIST &&
      getStyleParamValue(widgetStyles.widgetType) !== WIDGET_TYPE.FEATURED
        ? wixColorParam('color-1', 0)({colors})
        : getStyleParamValue(widgetStyles.cardBackgroundColor),
  },
  listItemBorderAndDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) =>
      getStyleParamValue(widgetStyles.listLayout) === LIST_LAYOUT.LIST
        ? getStyleParamValue(widgetStyles.listDividerWidth)
        : getStyleParamValue(widgetStyles.cardBorderWidth),
  },
  listButtonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 1),
  },
  listButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 1),
  },
  todayButtonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 1),
  },
  todayButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 1),
  },
  listImageBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 1),
  },
  multiImageBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: () => undefined,
  },
  multiImageBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  ribbonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: () => customColor('#F64D43'),
  },
  soldOutRibbonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: () => customColor('#F64D43'),
  },
  rsvpClosedRibbonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: () => customColor('#F64D43'),
  },
  joinWaitlistRibbonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: () => customColor('#F64D43'),
  },
  membershipRibbonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: getDefaultRibbonColor,
  },
  recurringRibbonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: getDefaultRibbonColor,
  },
  imageBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  backgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  borderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-2', 1),
  },
  onImageButtonsBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  buttonsBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 1),
  },
  onImageButtonsBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  buttonsBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 1),
  },
  onImageDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  dividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  calendarBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0),
  },
  listHeaderDateColor: {
    type: StyleParamType.Color,
    getDefaultValue: () => undefined,
  },
  listLoadMoreContainerBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 1),
  },
  listShowCountdown: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  listCountdownFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 12,
    }),
  },
  listCountdownColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  listCountdownBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  listCountdownBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.4),
  },
  listCountdownTopLeftBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 30,
  },
  listCountdownTopRightBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 30,
  },
  listCountdownBottomLeftBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 30,
  },
  listCountdownBottomRightBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 30,
  },
  itemFixedHeight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 500,
  },
  itemHeightType: {
    type: StyleParamType.Number,
    getDefaultValue: () => ITEM_HEIGHT_TYPE.DYNAMIC,
  },
  topLeftBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  topRightBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  bottomLeftBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  bottomRightBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  eventBoxShadowVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  eventBoxShadowAngle: {
    type: StyleParamType.Number,
    getDefaultValue: () => 135,
  },
  eventBoxShadowDistance: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  eventBoxShadowBlur: {
    type: StyleParamType.Number,
    getDefaultValue: () => 25,
  },
  eventBoxShadowColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.12),
  },
  imageOverlayColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.45),
  },
  listImageHoverEffect: {
    type: StyleParamType.Number,
    getDefaultValue: () => IMAGE_HOVER_EFFECT.ZOOM_IN,
  },
  listTextVerticalAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue, isMobile}) =>
      !isMobile && [LIST_LAYOUT.SIDE_BY_SIDE, LIST_LAYOUT.LIST].includes(getStyleParamValue(widgetStyles.listLayout))
        ? VERTICAL_ALIGNMENT.CENTER
        : VERTICAL_ALIGNMENT.TOP,
  },
  listShowMembers: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({getStyleParamValue, isMobile}) => {
      const onImage = isMobile
        ? getStyleParamValue(widgetStyles.mobileWidgetLayout) === MOBILE_WIDGET_LAYOUT.ON_IMAGE
        : getStyleParamValue(widgetStyles.listLayout) === LIST_LAYOUT.ON_IMAGE

      return !onImage
    },
  },
  ...widgetStylesV2,
  ...hoveredWidgetStylesV2,
}

export const getDefaultNumbersAndBooleans = ({isMobile, isRTL, getStyleParamValue = () => null}) =>
  Object.entries(styleParams).reduce((acc, [key, value]) => {
    if (value.type === StyleParamType.Boolean || value.type === StyleParamType.Number) {
      acc[key] = value.getDefaultValue({isMobile, isRTL, getStyleParamValue} as any)
      return acc
    }
    return acc
  }, {})

export default createStylesParams<IStyles>(styleParams)

export {customCssVars} from './custom-css-vars'
