import {EVENT_FILTER_TYPE, LIST_LAYOUT} from '@wix/wix-events-commons-statics'
import {IDimensions} from '@wix/yoshi-flow-editor'
import {isFullWidth, isMobile, isSite} from '../../../../commons/selectors/environment'
import {WidgetComponentConfig} from '../../types'
import {MOBILE_WIDTH} from '../components/list-layout/list/constants'
import {State} from '../types/state'
import {getEvents, getRealEvents} from './events'
import {getComponentConfig, isFeaturedWidget, isMultiEventWidget, isResponsive} from './settings'

// These selectors cannot use getValue or take value from componentConfig, unless it's a global setting
// Global setting is set in settings using "isGlobal: true"

export const isNarrow = (state: State, dimensions: IDimensions) => {
  if (isResponsive(getComponentConfig(state))) {
    return isMobile(state)
  }

  return !isFullWidth(state, dimensions) && dimensions.width < MOBILE_WIDTH
}

export const getListLayout = (componentConfig: WidgetComponentConfig) => componentConfig.settings.listLayout

export const isCardsLayout = (componentConfig: WidgetComponentConfig) =>
  (isFeaturedWidget(componentConfig) || isMultiEventWidget(componentConfig)) &&
  (getListLayout(componentConfig) === LIST_LAYOUT.NEW_GRID || getListLayout(componentConfig) === LIST_LAYOUT.GRID)

export const isCalendarLayout = (componentConfig: WidgetComponentConfig) =>
  isMultiEventWidget(componentConfig) && getListLayout(componentConfig) === LIST_LAYOUT.CALENDAR

export const isEmptyStateNotificationVisible = (state: State) => {
  if (
    !isCalendarLayout(state.component) &&
    state.events.filterType === EVENT_FILTER_TYPE.MANUAL &&
    !getRealEvents(state).length &&
    !isSite(state)
  ) {
    return true
  }

  return !isCalendarLayout(state.component) && !getEvents(state).length && isSite(state)
}

export const getCalendarWeekStartDay = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.calendarWeekStartDay
