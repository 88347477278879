import {createAction} from '@reduxjs/toolkit'
import {EVENT_FILTER_TYPE, FEATURED_EVENT_DISPLAY_STRATEGY, WIDGET_TYPE} from '@wix/wix-events-commons-statics'
import {getLocale, isEditor, isMobile} from '../../../../commons/selectors/environment'
import {isEventsInMembersInstalled, isPaidPlansInstalled} from '../../../../commons/selectors/installed-apps'
import {isDemo, isTemplate} from '../../../../commons/selectors/instance'
import settingsParams from '../../settingsParams'
import {isCalendarPageLoaded} from '../selectors/calendar-layout'
import {getComponentStatusFilter, getFilterTypeFromSettings, isManualFilterSelected} from '../selectors/component'
import {getRealEventsCount} from '../selectors/events'
import {isCalendarLayout} from '../selectors/list-settings'
import {getRecurringFilter} from '../selectors/settings'
import {getDynamicCategoryIds} from '../selectors/velo-overrides'
import {createAsyncAction} from '../services/redux-toolkit'
import {CalendarLoading} from '../types/state'
import {LoadCalendarEventsResponse, LoadEventsResponse, LoadFeaturedEventResponse} from '../utils/api'
import {resetCalendar} from './calendar-layout'

export const setFilterType = createAction<EVENT_FILTER_TYPE>('SET_FILTER_TYPE')

export const reloadEvents = createAsyncAction('RELOAD_EVENTS', (_, {getState, dispatch}) => {
  const featuredWidget = getState().component.settings.widgetType === WIDGET_TYPE.FEATURED

  isCalendarLayout(getState().component)
    ? dispatch(resetCalendar(true))
    : featuredWidget
    ? dispatch(loadFeaturedEvent())
    : dispatch(loadEventsPage({offset: 0}))
})

interface LoadEventsPageParams {
  offset?: number
  limit?: number
}

export const loadEventsPage = createAsyncAction<LoadEventsResponse, LoadEventsPageParams>(
  'LOAD_EVENTS_PAGE',
  ({offset, limit}, {getState, extra: {serverApi, flowAPI}}) => {
    const state = getState()
    offset = offset ?? getRealEventsCount(state)
    const categoryId = getDynamicCategoryIds(state, flowAPI)
    const recurringFilter = getRecurringFilter(state)
    const eventsInMembersInstalled = isEventsInMembersInstalled(state)
    const paidPlansInstalled = isPaidPlansInstalled(state)

    const filterTypeFromSettings = getFilterTypeFromSettings(state, flowAPI)
    const sortOrder = flowAPI.settings.get(settingsParams.sortOrder) as number
    const filter = getComponentStatusFilter(state, flowAPI)

    return serverApi.loadEvents({
      offset,
      filter,
      byEventId: isManualFilterSelected(flowAPI),
      members: eventsInMembersInstalled,
      paidPlans: paidPlansInstalled,
      locale: getLocale(state),
      categoryId,
      recurringFilter,
      filterType: filterTypeFromSettings,
      sortOrder,
      limit,
    })
  },
)

export const loadFeaturedEvent = createAsyncAction<LoadFeaturedEventResponse>(
  'LOAD_FEATURED_EVENT',
  (_, {getState, extra: {serverApi, flowAPI}}) => {
    const state = getState()

    return serverApi.loadFeaturedEvent({
      members: isEventsInMembersInstalled(state),
      paidPlans: isPaidPlansInstalled(state),
      locale: getLocale(state),
      categoryId: flowAPI.settings.get(settingsParams.featuredCategoryId) as string,
      eventId: flowAPI.settings.get(settingsParams.featuredEventId) as string,
      manuallySelected:
        flowAPI.settings.get(settingsParams.featuredEventDisplayStrategy) === FEATURED_EVENT_DISPLAY_STRATEGY.CUSTOM,
    })
  },
)

interface LoadCalendarEventsParams {
  referenceDate?: string
  origin?: CalendarLoading
  fullReset?: boolean
}

export const loadCalendarEvents = createAsyncAction<LoadCalendarEventsResponse, LoadCalendarEventsParams>(
  'LOAD_CALENDAR_EVENTS',
  ({fullReset, referenceDate}, {getState, extra: {serverApi, flowAPI}}) => {
    const state = getState()

    if (!fullReset && referenceDate && isCalendarPageLoaded(state.calendarLayout, referenceDate)) {
      return
    }

    const eventsInMembersInstalled = isEventsInMembersInstalled(state)
    const paidPlansInstalled = isPaidPlansInstalled(state)
    const categoryId = getDynamicCategoryIds(state, flowAPI)
    const showcase = isDemo(state) || isTemplate(state) || isEditor(state) || isMobile(state)

    const filterTypeFromSettings = getFilterTypeFromSettings(state, flowAPI)
    const sortOrder = flowAPI.settings.get(settingsParams.sortOrder) as number
    const filter = getComponentStatusFilter(state, flowAPI)

    return serverApi.loadCalendarEvents({
      referenceDate,
      filter,
      byEventId: isManualFilterSelected(flowAPI),
      members: eventsInMembersInstalled,
      paidPlans: paidPlansInstalled,
      locale: getLocale(state),
      categoryId,
      showcase,
      filterType: filterTypeFromSettings,
      sortOrder,
      multiDayExperimentEnabled: true,
      expandBounds: true,
    })
  },
)
