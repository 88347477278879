import {IStyleParam, IWixStyleFont, StyleParamType} from '@wix/tpa-settings'
import {LIST_LAYOUT, MOBILE_LIST_LAYOUT_TYPE} from '@wix/wix-events-commons-statics'
import {widgetStyles} from '../../../stylesParams'
import {ADDITIONAL_MOBILE_LIST_LAYOUT, WidgetText} from './constants'

export interface FontConfig {
  font?: IStyleParam<StyleParamType.Font>
  color?: IStyleParam<StyleParamType.Color>
  size?: IStyleParam<StyleParamType.Number>
  hoverFont?: IStyleParam<StyleParamType.Font>
  hoverColor?: IStyleParam<StyleParamType.Color>
  hoverSize?: IStyleParam<StyleParamType.Number>
  staticFontSize?: number
  hoverStaticFontSize?: number
  style?: Partial<IWixStyleFont['style']>
}

type WidgetFontMap = Partial<Record<WidgetText, FontConfig>>

export interface StudioParamsMap {
  desktop: Record<Exclude<LIST_LAYOUT, LIST_LAYOUT.ON_IMAGE>, WidgetFontMap>
  mobile: Record<
    Exclude<MOBILE_LIST_LAYOUT_TYPE, MOBILE_LIST_LAYOUT_TYPE.CARDS> | ADDITIONAL_MOBILE_LIST_LAYOUT,
    WidgetFontMap
  >
}

const LINK_STATIC_FONT_SIZE = 16
const LIST_EXPANDED_STATIC_FONT_SIZE = 16

const getCalendarMap = (): WidgetFontMap => ({
  [WidgetText.LIST_TITLE]: {
    font: widgetStyles.listGeneralTitleFont,
  },
})

const getCardsMap = (): WidgetFontMap => ({
  [WidgetText.LIST_TITLE]: {
    font: widgetStyles.listGeneralTitleFont,
  },
  [WidgetText.TITLE]: {
    font: widgetStyles.listHeaderTitleFont,
    color: widgetStyles.listHeaderTitleColor,
  },
  [WidgetText.DATE]: {
    font: widgetStyles.listHeaderLocationFont,
    color: widgetStyles.listHeaderTitleColor,
    hoverFont: widgetStyles.listHeaderLocationFont,
    hoverColor: widgetStyles.listHeaderTitleColor,
    hoverSize: widgetStyles.cardLocationAndDateFontSize,
  },
  [WidgetText.DESCRIPTION]: {
    hoverFont: widgetStyles.listHeaderLocationFont,
    hoverColor: widgetStyles.listHeaderTitleColor,
    hoverSize: widgetStyles.cardDescriptionFontSize,
  },
  [WidgetText.LOCATION]: {
    font: widgetStyles.listHeaderLocationFont,
    color: widgetStyles.listHeaderTitleColor,
    hoverFont: widgetStyles.listHeaderLocationFont,
    hoverColor: widgetStyles.listHeaderTitleColor,
    hoverSize: widgetStyles.cardLocationAndDateFontSize,
  },
  [WidgetText.LINK]: {
    font: widgetStyles.listHeaderLocationFont,
    color: widgetStyles.listHeaderTitleColor,
    staticFontSize: LINK_STATIC_FONT_SIZE,
    style: {underline: true},
  },
})

const getListMap = (): WidgetFontMap => ({
  [WidgetText.LIST_TITLE]: {
    font: widgetStyles.listGeneralTitleFont,
  },
  [WidgetText.TITLE]: {
    font: widgetStyles.listHeaderTitleFont,
    color: widgetStyles.listHeaderTitleColor,
    hoverFont: widgetStyles.listHeaderTitleFont,
    hoverColor: widgetStyles.listContentTitleColor,
  },
  [WidgetText.DATE]: {
    font: widgetStyles.listHeaderDateTimeFont,
    color: widgetStyles.listHeaderDateTimeColor,
    hoverFont: widgetStyles.listContentTextFont,
    hoverColor: widgetStyles.listContentTextColor,
    hoverStaticFontSize: LIST_EXPANDED_STATIC_FONT_SIZE,
  },
  [WidgetText.LOCATION]: {
    font: widgetStyles.listHeaderLocationFont,
    color: widgetStyles.listHeaderLocationColor,
    hoverFont: widgetStyles.listContentTextFont,
    hoverColor: widgetStyles.listContentTextColor,
    hoverStaticFontSize: LIST_EXPANDED_STATIC_FONT_SIZE,
  },
  [WidgetText.DESCRIPTION]: {
    hoverFont: widgetStyles.listContentTextFont,
    hoverColor: widgetStyles.listContentTextColor,
    hoverStaticFontSize: LIST_EXPANDED_STATIC_FONT_SIZE,
  },
  [WidgetText.LINK]: {
    font: widgetStyles.listContentTextFont,
    color: widgetStyles.listContentTextColor,
    staticFontSize: LINK_STATIC_FONT_SIZE,
    style: {underline: true},
  },
})

const getSideBySideMap = (): WidgetFontMap => ({
  [WidgetText.LIST_TITLE]: {
    font: widgetStyles.listGeneralTitleFont,
  },
  [WidgetText.TITLE]: {
    font: widgetStyles.listHeaderTitleFont,
    color: widgetStyles.listHeaderTitleColor,
  },
  [WidgetText.DATE]: {
    font: widgetStyles.listHeaderLocationFont,
    color: widgetStyles.listHeaderTitleColor,
  },
  [WidgetText.LOCATION]: {
    font: widgetStyles.listHeaderLocationFont,
    color: widgetStyles.listHeaderTitleColor,
  },
  [WidgetText.DESCRIPTION]: {
    font: widgetStyles.cardDescriptionFont,
    color: widgetStyles.listHeaderTitleColor,
  },
  [WidgetText.LINK]: {
    font: widgetStyles.listHeaderLocationFont,
    color: widgetStyles.listHeaderTitleColor,
    staticFontSize: LINK_STATIC_FONT_SIZE,
    style: {underline: true},
  },
})

const getCompactMap = (): WidgetFontMap => ({
  [WidgetText.LIST_TITLE]: {
    font: widgetStyles.listGeneralTitleFont,
    size: widgetStyles.listGeneralTitleFontSizeMobile,
  },
  [WidgetText.TITLE]: {
    font: widgetStyles.listHeaderTitleFont,
    color: widgetStyles.listHeaderTitleColor,
    size: widgetStyles.listHeaderTitleFontSizeCompactMobile,
  },
  [WidgetText.DATE]: {
    font: widgetStyles.listHeaderTitleFont,
    color: widgetStyles.listHeaderTitleColor,
    size: widgetStyles.listDateFontSizeCompactMobile,
  },
  [WidgetText.LOCATION]: {
    font: widgetStyles.listHeaderTitleFont,
    color: widgetStyles.listHeaderTitleColor,
    size: widgetStyles.listLocationFontSizeCompactMobile,
  },
  [WidgetText.DESCRIPTION]: {},
  [WidgetText.LINK]: {},
})

const getFullMap = (): WidgetFontMap => ({
  [WidgetText.LIST_TITLE]: {
    font: widgetStyles.listGeneralTitleFont,
    size: widgetStyles.listGeneralTitleFontSizeMobile,
  },
  [WidgetText.TITLE]: {
    font: widgetStyles.listHeaderTitleFont,
    color: widgetStyles.listHeaderTitleColor,
    size: widgetStyles.listHeaderTitleFontSizeMobile,
  },
  [WidgetText.DATE]: {
    font: widgetStyles.listHeaderDateTimeFont,
    color: widgetStyles.listHeaderDateTimeColor,
    size: widgetStyles.listDateFontSizeMobile,
    hoverFont: widgetStyles.listContentTextFont,
    hoverColor: widgetStyles.listContentTextColor,
    hoverSize: widgetStyles.listContentTextFontSizeMobile,
  },
  [WidgetText.LOCATION]: {
    hoverFont: widgetStyles.listContentTextFont,
    hoverColor: widgetStyles.listContentTextColor,
    hoverSize: widgetStyles.listContentTextFontSizeMobile,
  },
  [WidgetText.DESCRIPTION]: {
    hoverFont: widgetStyles.listContentTextFont,
    hoverColor: widgetStyles.listContentTextColor,
    hoverSize: widgetStyles.listContentTextFontSizeMobile,
  },
  [WidgetText.LINK]: {
    font: widgetStyles.listHeaderTitleFont,
    color: widgetStyles.listContentTextColor,
    staticFontSize: LINK_STATIC_FONT_SIZE,
    style: {underline: true},
  },
})

const getGridMap = (): WidgetFontMap => ({
  [WidgetText.LIST_TITLE]: {
    font: widgetStyles.listGeneralTitleFont,
    size: widgetStyles.listGeneralTitleFontSizeMobile,
  },
  [WidgetText.TITLE]: {
    font: widgetStyles.listHeaderTitleFont,
    color: widgetStyles.listHeaderTitleColor,
    size: widgetStyles.listHeaderTitleFontSizeMobile,
  },
  [WidgetText.DATE]: {
    font: widgetStyles.listHeaderTitleFont,
    color: widgetStyles.listHeaderTitleColor,
    size: widgetStyles.listDateFontSizeMobile,
    hoverFont: widgetStyles.listHeaderLocationFont,
    hoverColor: widgetStyles.listHeaderTitleColor,
    hoverSize: widgetStyles.listContentTextFontSizeMobile,
  },
  [WidgetText.LOCATION]: {
    hoverFont: widgetStyles.listHeaderLocationFont,
    hoverColor: widgetStyles.listHeaderTitleColor,
    hoverSize: widgetStyles.listContentTextFontSizeMobile,
  },
  [WidgetText.DESCRIPTION]: {
    hoverFont: widgetStyles.listHeaderLocationFont,
    hoverColor: widgetStyles.listHeaderTitleColor,
    hoverSize: widgetStyles.listContentTextFontSizeMobile,
  },
  [WidgetText.LINK]: {
    font: widgetStyles.listHeaderTitleFont,
    color: widgetStyles.listHeaderTitleColor,
    staticFontSize: LINK_STATIC_FONT_SIZE,
    style: {underline: true},
  },
})

export const getV2ParamsMap = (): StudioParamsMap => {
  const desktopGridMap = getCardsMap()

  return {
    desktop: {
      [LIST_LAYOUT.NEW_GRID]: desktopGridMap,
      [LIST_LAYOUT.GRID]: desktopGridMap,
      [LIST_LAYOUT.LIST]: getListMap(),
      [LIST_LAYOUT.SIDE_BY_SIDE]: getSideBySideMap(),
      [LIST_LAYOUT.CALENDAR]: getCalendarMap(),
    },
    mobile: {
      [MOBILE_LIST_LAYOUT_TYPE.STRIPS]: getCompactMap(),
      [MOBILE_LIST_LAYOUT_TYPE.FULL]: getFullMap(),
      [ADDITIONAL_MOBILE_LIST_LAYOUT.GRID]: getGridMap(),
    },
  }
}
