import {IStyleParam, StyleParamType} from '@wix/tpa-settings'
import {
  LIST_LAYOUT,
  LIST_LOCATION_AND_DATE_FORMAT,
  MOBILE_LIST_LAYOUT_TYPE,
  MOBILE_WIDGET_LAYOUT,
} from '@wix/wix-events-commons-statics'
import {GetDefaultValueProps} from '../../../../settings-commons/utils/style'
import {widgetStyles} from '../../stylesParams'
import {isCards, isCompactMobile, isList} from './utils'

export const getWidgetLocationDateFormatDefault = (params: GetDefaultValueProps) => {
  const {getStyleParamValue} = params
  const listLayout = getStyleParamValue(widgetStyles.listLayout) as LIST_LAYOUT

  if (isList(listLayout)) {
    return LIST_LOCATION_AND_DATE_FORMAT.COMPACT
  }

  return getStyleParamValue(
    isCards(listLayout) ? widgetStyles.cardLocationAndDateFormat : widgetStyles.listLocationAndDateFormat,
  )
}

export const getWidgetDateVisibilityDefault = (params: GetDefaultValueProps) => {
  return getWidgetLocationAndDateVisibility(params, widgetStyles.listShowDate, widgetStyles.listShowFullDate, true)
}

export const getWidgetLocationVisibilityDefault = (params: GetDefaultValueProps) => {
  return getWidgetLocationAndDateVisibility(params, widgetStyles.listShowVenueName, widgetStyles.listShowLocation)
}

const getWidgetLocationAndDateVisibility = (
  params: GetDefaultValueProps,
  showCompact: IStyleParam<StyleParamType.Boolean, boolean>,
  showFull: IStyleParam<StyleParamType.Boolean, boolean>,
  isDate?: boolean,
) => {
  const {getStyleParamValue, isMobile} = params
  const listLayout = getStyleParamValue(widgetStyles.listLayout) as LIST_LAYOUT
  const mobileLayout = getStyleParamValue(widgetStyles.mobileWidgetLayout) as MOBILE_WIDGET_LAYOUT
  const mobileListLayoutType = getStyleParamValue(widgetStyles.mobileListLayoutType) as MOBILE_LIST_LAYOUT_TYPE

  const showCompactValue = getStyleParamValue(showCompact)
  const showFullValue = getStyleParamValue(showFull)

  if (isMobile) {
    return isCompactMobile(mobileLayout, mobileListLayoutType) && !isDate ? showFullValue : showCompactValue
  }

  if (isList(listLayout)) {
    return getStyleParamValue(showCompact)
  }

  const compact = isCompactFormat(
    getStyleParamValue(
      isCards(listLayout) ? widgetStyles.cardLocationAndDateFormat : widgetStyles.listLocationAndDateFormat,
    ),
  )

  return getStyleParamValue(compact ? showCompact : showFull)
}

const isCompactFormat = (format: LIST_LOCATION_AND_DATE_FORMAT) => format === LIST_LOCATION_AND_DATE_FORMAT.COMPACT
