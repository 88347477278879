import {ISiteTextPreset, IWixStyleFont, wixFontParam} from '@wix/tpa-settings'
import {Experiments} from '@wix/yoshi-flow-editor'

export interface GetDefaultValueProps {
  experiments: Pick<Experiments, 'enabled' | 'get'>
  colors: any[]
  dimensions: {height: number; width: number}
  getStyleParamValue: any
  isEditorX: boolean
  isMobile: boolean
  isRTL: boolean
  textPresets: any
}

export const getDefaultFont =
  (name: keyof ISiteTextPreset, options: Partial<IWixStyleFont> = {}) =>
  ({textPresets}: {textPresets: ISiteTextPreset}) => {
    const {value: _, ...font} = wixFontParam(name, options)({textPresets})

    return font as IWixStyleFont
  }

export const areSameValues = (values: number[]) => values.every(value => value === values[0])
