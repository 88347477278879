import {isDateTbd, isEnded, isStarted, isStartingToday} from '@wix/wix-events-commons-statics'
import {State} from '../types/state'
import {getFirstEvent} from './events'
import {isCalendarLayout} from './list-settings'
import {getComponentConfig} from './settings'

export const isItemOpened = (state: State, eventId: string) => state.layout.listItemOpened === eventId

export const getForcedRibbon = (state: State) => state.layout.forceShowRibbon

export const shouldForceShowRibbon = (state: State, event: wix.events.Event) =>
  !!getForcedRibbon(state) && (getFirstEvent(state)?.id === event.id || isCalendarLayout(getComponentConfig(state)))

export const shouldShowCountdown = (event: wix.events.Event) =>
  !isEnded(event) && !isDateTbd(event) && !isStartingToday(event) && !isStarted(event)
