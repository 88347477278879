import {INNER_PAGE, TAB} from '../../../../commons/constants/navigation'
import {RIBBON} from '../../../../commons/constants/ribbons'
import {SettingsNavigationInfo} from '../../Settings/utils/history'
import {getFirstEvent} from '../selectors/events'
import {isCalendarLayout} from '../selectors/list-settings'
import {getComponentConfig} from '../selectors/settings'
import {GetState} from '../types/state'
import {closeMonthlyCalendarPopup, openAnyEventDetails, openAnyEventList} from './calendar-layout'
import {closeListLayoutItems, hideRibbonOnItems, openListLayoutItem, showRibbonOnItems} from './layout'

export const SETTINGS_TAB_CHANGED = 'SETTINGS_TAB_CHANGED'

export const settingsTabChanged =
  (navigationInfo: SettingsNavigationInfo) => (dispatch: Function, getState: GetState) => {
    if (getState().environment.mobile) {
      handleSettingsTabChangeOnMobile(dispatch, getState, navigationInfo)
    } else {
      handleSettingsTabChangeOnDesktop(dispatch, getState, navigationInfo)
    }
  }

const handleSettingsTabChangeOnMobile = (dispatch: Function, getState: GetState, {tab}: SettingsNavigationInfo) => {
  if ([TAB.DISPLAY, TAB.DESIGN].includes(tab)) {
    const event = getFirstEvent(getState())
    if (event) {
      dispatch(openListLayoutItem(event.id))
    }
  } else {
    dispatch(closeListLayoutItems())
  }
}

const handleSettingsTabChangeOnDesktop = (
  dispatch: Function,
  getState: GetState,
  {tab, innerPage, subPage}: SettingsNavigationInfo,
) => {
  if (isCalendarLayout(getComponentConfig(getState()))) {
    if (
      [TAB.DISPLAY, TAB.LAYOUT].includes(tab) ||
      [
        INNER_PAGE.DESIGN_CALENDAR_DETAILS_POPUP,
        INNER_PAGE.DESIGN_BUTTONS,
        INNER_PAGE.DESIGN_RIBBONS,
        INNER_PAGE.DESIGN_RIBBON,
        INNER_PAGE.TEXT_RSVP_CLOSED,
        INNER_PAGE.TEXT_RSVP,
        INNER_PAGE.TEXT_RIBBON,
        INNER_PAGE.DESIGN_IMAGE,
      ].includes(innerPage as INNER_PAGE)
    ) {
      dispatch(openAnyEventDetails())
    } else if (INNER_PAGE.DESIGN_CALENDAR_LIST_POPUP === innerPage) {
      dispatch(openAnyEventList())
    } else {
      dispatch(closeMonthlyCalendarPopup())
    }
  } else {
    if (innerPage === INNER_PAGE.DESIGN_HOVER || tab === TAB.DISPLAY) {
      const event = getFirstEvent(getState())
      if (event) {
        dispatch(openListLayoutItem(event.id))
      }
    } else {
      dispatch(closeListLayoutItems())
    }
  }

  if (subPage) {
    dispatch(showRibbonOnItems(subPage as RIBBON))
  } else {
    dispatch(hideRibbonOnItems())
  }
}
