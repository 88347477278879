import {IWixStyleFont} from '@wix/tpa-settings'

export enum WidgetText {
  LIST_TITLE = 'LIST_TITLE',
  TITLE = 'TITLE',
  DATE = 'DATE',
  LOCATION = 'LOCATION',
  DESCRIPTION = 'DESCRIPTION',
  LINK = 'LINK',
}

export enum ADDITIONAL_MOBILE_LIST_LAYOUT {
  GRID = 'GRID',
}

export const DEFAULT_FONT_STYLE: IWixStyleFont['style'] = {
  bold: false,
  italic: false,
  underline: false,
}
