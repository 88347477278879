import {IWixWindowViewMode} from '@wix/native-components-infra/dist/es/src/types/types'
import {IHostProps} from '@wix/yoshi-flow-editor'
import {Component} from 'react'
import type {widgetLoaded} from '../../actions/layout'

export interface AppLoadedProps {
  widgetLoaded: typeof widgetLoaded
  viewMode: IWixWindowViewMode
  isHomePage: boolean
  host: IHostProps
}

export class AppLoaded extends Component<AppLoadedProps> {
  componentDidMount() {
    if (this.props.viewMode === 'Site') {
      this.props.widgetLoaded(this.props.isHomePage)
    }
  }

  render() {
    return null
  }
}
