import {LIST_LAYOUT, MOBILE_LIST_LAYOUT_TYPE, MOBILE_WIDGET_LAYOUT} from '@wix/wix-events-commons-statics'
import {MOBILE_WIDTH} from '../components/list-layout/list/constants'

export const isCards = (listLayout: LIST_LAYOUT) => [LIST_LAYOUT.GRID, LIST_LAYOUT.NEW_GRID].includes(listLayout)

export const isOnImage = (listLayout: LIST_LAYOUT) => listLayout === LIST_LAYOUT.ON_IMAGE

export const isList = (listLayout: LIST_LAYOUT) => listLayout === LIST_LAYOUT.LIST

export const isSideBySide = (listLayout: LIST_LAYOUT) => listLayout === LIST_LAYOUT.SIDE_BY_SIDE

export const isMobileOnImage = (mobileLayout: MOBILE_WIDGET_LAYOUT) => mobileLayout === MOBILE_WIDGET_LAYOUT.ON_IMAGE

export const isGridMobile = (mobileLayout: MOBILE_WIDGET_LAYOUT) => mobileLayout === MOBILE_WIDGET_LAYOUT.GRID

export const isFullMobile = (mobileLayout: MOBILE_WIDGET_LAYOUT, mobileListLayoutType: MOBILE_LIST_LAYOUT_TYPE) =>
  isListMobile(mobileLayout) && isFullListLayoutTypeMobile(mobileListLayoutType)

export const isCompactMobile = (mobileLayout: MOBILE_WIDGET_LAYOUT, mobileListLayoutType: MOBILE_LIST_LAYOUT_TYPE) =>
  isListMobile(mobileLayout) && !isFullListLayoutTypeMobile(mobileListLayoutType)

export const isNarrow = (listLayout: LIST_LAYOUT, width: number | string, isMobile: boolean) =>
  !isMobile && isList(listLayout) && typeof width === 'number' && width < MOBILE_WIDTH

const isListMobile = (mobileLayout: MOBILE_WIDGET_LAYOUT) => mobileLayout === MOBILE_WIDGET_LAYOUT.LIST

const isFullListLayoutTypeMobile = (type: MOBILE_LIST_LAYOUT_TYPE) => type === MOBILE_LIST_LAYOUT_TYPE.FULL
