import loadable from '@wix/yoshi-flow-editor/loadable'

export const SingleEvent = loadable(() => import(/* webpackChunkName: "SingleEvent" */ '../single-event'))
export const CompactMobile = loadable(
  () => import(/* webpackChunkName: "CompactMobile" */ '../mobile-layouts/compact-mobile'),
)
export const CardsMobile = loadable(
  () => import(/* webpackChunkName: "CardsMobile" */ '../mobile-layouts/cards-mobile'),
)
export const Calendar = loadable(() => import(/* webpackChunkName: "Calendar" */ '../calendar'))
export const Cards = loadable(() => import(/* webpackChunkName: "Cards" */ '../cards'))
export const EmptyState = loadable(() => import(/* webpackChunkName: "EmptyState" */ '../empty-state'))
export const ListLayout = loadable(() => import(/* webpackChunkName: "ListLayout" */ '../list-layout'))
export const SideBySide = loadable(() => import(/* webpackChunkName: "SideBySide" */ '../side-by-side'))
export const OnImage = loadable(() => import(/* webpackChunkName: "OnImage" */ '../on-image'))
