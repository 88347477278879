import {StatusFilter} from '@wix/events-types'
import {EVENT_FILTER_TYPE, ExperimentNames, LIST_DISPLAY_STRATEGY} from '@wix/wix-events-commons-statics'
import type {ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import settingsParams from '../../settingsParams'
import {getStatusFilter} from '../../utils/status-filter'
import type {State} from '../types/state'
import {getDynamicCategoryIds, isVeloOverrideUsed} from './velo-overrides'

export const getFilterTypeFromSettings = (state: State, flowAPI: ControllerFlowAPI) => {
  const updatedDisplayListEventsPanelEnabled = flowAPI.experiments.enabled(
    ExperimentNames.UpdatedDisplayListEventsPanel,
  )
  const manualDisplayStrategy =
    flowAPI.settings.get(settingsParams.listDisplayStrategy) === LIST_DISPLAY_STRATEGY.CUSTOM
  const filter = flowAPI.settings.get(settingsParams.filterType) as number
  const filterV2 = flowAPI.settings.get(settingsParams.listStatusFilter) as number

  const resolvedFilter = updatedDisplayListEventsPanelEnabled
    ? manualDisplayStrategy
      ? EVENT_FILTER_TYPE.MANUAL
      : filterV2
    : filter

  if (isVeloOverrideUsed(state) && resolvedFilter === EVENT_FILTER_TYPE.MANUAL) {
    return EVENT_FILTER_TYPE.UPCOMING_AND_PAST
  }

  return resolvedFilter
}

export const getCompId = (state: State) => state.component.id

export const getComponentStatusFilter = (_state: State, flowAPI: ControllerFlowAPI) => {
  const filterTypeFromSettings = flowAPI.settings.get(settingsParams.filterType) as number
  const filter: StatusFilter = getStatusFilter(filterTypeFromSettings)

  return filter
}

export const getComponentCategoryIds = (state: State, flowAPI: ControllerFlowAPI) =>
  getDynamicCategoryIds(state, flowAPI)

export const isManualFilterSelected = (flowAPI: ControllerFlowAPI) => {
  const filterTypeFromSettings = flowAPI.settings.get(settingsParams.filterType) as number

  return filterTypeFromSettings === EVENT_FILTER_TYPE.MANUAL
}
